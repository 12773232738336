// extracted by mini-css-extract-plugin
export var grid = "TestimonialSection-module--grid--10fa6";
export var gridH2 = "TestimonialSection-module--gridH2--c9388";
export var gridH3 = "TestimonialSection-module--gridH3--6cf58";
export var gridItem = "TestimonialSection-module--gridItem--b287c";
export var gridParagraph = "TestimonialSection-module--gridParagraph--45c3c";
export var headingH2 = "TestimonialSection-module--headingH2--9aa2c";
export var headingH3 = "TestimonialSection-module--headingH3--a45d7";
export var iconWrapper = "TestimonialSection-module--iconWrapper--9d63f";
export var opacityEnter = "TestimonialSection-module--opacityEnter--74a9b";
export var opacityExit = "TestimonialSection-module--opacityExit--8e13f";
export var paragraph = "TestimonialSection-module--paragraph--b727d";
export var rollDown = "TestimonialSection-module--rollDown--f2a74";
export var rollUp = "TestimonialSection-module--rollUp--8f635";
export var root = "TestimonialSection-module--root--b2bd7";
export var slideInDown = "TestimonialSection-module--slideInDown--84fe5";
export var slideOutUp = "TestimonialSection-module--slideOutUp--5347a";
export var splashEnter = "TestimonialSection-module--splashEnter--98fcc";
export var splashExit = "TestimonialSection-module--splashExit--e0717";