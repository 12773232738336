import React from 'react';
import Image from '@/components/elements/Image';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { TestimonialSectionTypes } from './TestimonialSection.types';
import * as styles from './TestimonialSection.module.scss';

export default function TestimonialSection({ body, grid }: TestimonialSectionTypes) {
  const gridStyles = {
    headingH2: styles.gridH2,
    headingH3: styles.gridH3,
    paragraph: styles.gridParagraph,
  };

  return (
    <ModuleWrapper>
      <SectionRichText body={body} className={styles} />
      {grid && (
        <ul className={styles.grid}>
          {grid.map(({ _uid, body, icon }) => (
            <li key={_uid} className={styles.gridItem}>
              {icon && (
                <div className={styles.iconWrapper}>
                  <Image data={icon} fixed options={{ width: 70 }} />
                </div>
              )}
              <SectionRichText body={body} className={gridStyles} />
            </li>
          ))}
        </ul>
      )}
    </ModuleWrapper>
  );
}
